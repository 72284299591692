.App {
  text-align: center;
}

.logo{
  width: 200px;
  margin-bottom: 20px;
  margin-top: 10px;
}

.container{
  margin: 10px;
}
/* Product Selection */
.productSelectionTop{
  background-color: #8B8B8B;
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
}

.productSelectionBottom{
  background-color: #14558F;
  border-radius: 0px;
  padding: 10px;
  color: white;
}
/* Meter */
.meter{
  background-color: rgba(0,0,0,0.5);
  padding: 20px;
}

.topMeter{
  margin-bottom: 25px;
}

.bottomMeter{

}
/* Choose Equipments */
.equipments{
  background-color: #DDD3C2;
  border-radius: 0px 0px 10px 10px;
  border-color: rgb(135, 135, 135);
  /* padding: 10px; */
  margin: auto !important;
}

.chooseEquipments{
  padding-bottom: 10px;
  padding: 10px;
  /* border-bottom: 1px solid #A9A9A9; */
}

.addedEquipments{
  background-color: #8B8B8B;
  border-radius: 0 0 10px 10px;
  /* border-bottom: 1px solid #606467; */
}

.paddedRow{
  padding: 10px;
}

.removeBTN{
  width: 20px;
  height: 20px;
}

.centerData{
  display: flex;
  justify-content: center;
  align-items: center;
}

.meterHeading{
  text-align: left;
  font-weight: bold;
  color: white;
  font-size: 12px;
}

select {
  min-width: 200px;
}

.equipmentLink:link{
  color: grey;
  text-decoration: none;
}

.equipmentLink:visited{
  color: grey;
  text-decoration: none;
}

.equipmentLink:hover{
  color: orange;
  text-decoration: none;
}

.equipment{
  background-color: transparent !important;
  width: 100% !important;
  max-width: 140px;
  box-shadow: none;
  border: none !important;
  margin: 0px !important;
}

.equipment:visited{
  background-color: transparent !important;
  width: 100% !important;
  max-width: 140px;
  box-shadow: none;
  border: none !important;

}

.equipment:hover{
  /* background-color: #e6e6e6; */
  background-color: white !important;
  border-radius: 10px;
  width: 100% !important;
  max-width: 140px;
}

.addedEquipmentContainer{
  display: flex;
  flex-direction: row;
  min-width: 100% !important;
  /* overflow-y: scroll; */
  /* overflow: hidden; */
  /* overflow: scroll; */
}


.chooseEquipmentImgContainer{
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  /* width: 100% !important; */
  height: 50px !important;
  padding: 5px;
  width: 100% !important;
  /* border: 1px solid red;  */

}

.equipmentImgContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
  padding-right: 0px;
  margin-left: 10px;
  overflow:scroll;
  offset:initial;
  /* Hide scrollbar from Microsoft Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

  /* Hide scrollbar from Chrome, Opera and safari */
.equipmentImgContainer::-webkit-scrollbar {
  display: none;
}

.flexLeft{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100% !important; */
}
.flexRight{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* width: 100% !important; */
}

.barText{
  font-size: x-small;
  font-family: Arial, Helvetica, sans-serif ;
  color: #cf4205;
}

.delImageDiv{
  justify-self:end;
  align-items: center;
  margin-left: 10px;
  background-color: white;
}
.delImage{
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
}

.rowWidth{
  width: 100% !important;
}

.equipmemtContainerFormat{
  flex-direction: row;
}

.equipmentImage{
  width: 50px;
  object-fit:contain;
  padding-right: 5px;
  height: auto;
}

.equipmentBody{
  height: 40px;
  padding: 0px !important;
}

.equipmentTitle{
  font-size: 10px !important;
  font-weight: bold !important;
}
/* Added Equipments */
.addedEquipmentRow{
}

.addedEquipmentColLeft{
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
}
.addedEquipmentColRight{
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.addedEquipment{
  background-color: #DDD4C2;
  border-radius: 10px;
  margin-bottom: 10px;
  /* width: 100% !important; */
  max-width: 100%;
  min-height: 50px;
  /* padding: 10px; */
  margin-left: 20px;
}

.addedEquipmentImgContainer{
  display: flex;
  justify-content: center;
  /* height: 100px !important; */
  height: 135px !important;
  padding: 10px;
}

.addedEquipmentImage{
  width: 100%;
}

.addedEquipmentBody{
  padding: 5px;
}

.addedEquipmentTitle{
  font-size: 12px !important;
  font-weight: bold !important;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  color: #14558F;
  margin-bottom: 10px;
}

.removeIcon{
  position: absolute;
  right: -4px;
  top: -10px;
}

.removeIconLink{
  color: red;
}

.addedLabel{
  text-align: left;
  font-size: 10px;
  font-weight: bold;
}

.addedEquipmentForm{
  margin-bottom: 0px;
  width: auto;
  height: auto;
  align-items: center;
}

.rowFormat{
  min-height: fit-content;
  /* justify-content: space-evenly; */
  width:auto;
  align-items: center;
}

.rowFormat2{
  min-height: fit-content;
  padding-left: 20px;
  /* justify-content: space-evenly; */
  width: auto;
  align-items: center;
  /* position: absolute; */
}

.rowFormat3{
  min-height: fit-content;
  padding-left: 20px;
  margin-right: 20px;
  
  /* justify-content: space-evenly; */
  width: auto;
  align-items: center;
  /* position: absolute; */
}

.badgeDiv{
  float: right;
  align-items:center;
  padding: 0%;
}

.progressBarDiv{
  float: right;
  align-items: center;
}

.addedEquipmentBadge{
  margin-left: 20px;
  position: relative;
  margin-right: 10px;
  align-items: center;
}
/* 
.removeIconCross{
  display: flex;
  width: 100px;
  height: 50px;
  padding-top: 20px;
  padding-left: 10px;
} */

.productImage div img{
  /* height: 120px;
  width: 250px; */
  display: block;
  box-shadow: 0 5px 15px transparent;
  border-radius: 5px;
  margin: auto
}

.modalImage{
  height: 120px;
  width: 250px;
}

.customProductInput{
  font-size: 10px !important;
  font-weight: bold !important;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  color: #14558F! important;
  margin-bottom: 10px;
}

.warning{
  display: flex;
  justify-content: center;
  align-items: center;
}

.warningImage{
  width: 50px;
  height: 30px;
  /* float: left */
}

.warningText{
  font-size: 10px;
  text-align: left;
  color: #cd511a;
}

.warningRow{
  align-items: center;
}

.modalBtn{
  background-color: #001C9A !important;
  border-color: #001C9A !important;
  color: white !important;
  padding: 10px 15px !important;
}

.progress{
  background-color: white important;
  background: white important;
}

.progress-bar{
  background-color: #cf4205 !important;;
}

.productSelect{
  text-align: left;
}

.btnContainer{
  /* display: flex !important; */
  /* align-items: center !important; */
}

.mt-4{
  margin-top: 20px !important;
}

.consumptionWarning p
{
  background-color: #cd511a;
  width: fit-content;
  margin: auto;
  padding: 5px 10px;
  margin: 15px auto 0px auto;
  border-radius: 5px;;
  color: white;
  font-size: 12px;
}

.consumptionHeading
{
  background-color: #FFFFFF;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px auto 10px auto;
}

.mainRow{
  margin-right: 0%;
}
.rowInline{
  display:flex;
  flex-direction: row;
  padding-right: 0%;
}

.leftt{
  float:left;
  width: 100%;
}
.rightt{
  float:right;
  width:50px;
  /* padding-left: 20px; */
}

.blueBG{
  background-color: #001C9A;
  padding: 15px;
}

.productHeading {
  font-size: 18px !important;
  font-weight: 100 !important;
  font-family: "Open Sans",arial, sans-serif !important;
  text-align: left !important;
}

.nopadding{
  padding: 0px !important;
}

